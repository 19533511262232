import React from "react";
import "./SDOH.scss";

function SDOH(props) {
  const { data } = props;
  return (
    <div className="sdoh-widget">
      <div className="widget-header">
        <div className="widget-title">Social determinants of health (SDOH)</div>
      </div>
      <div className="sdoh-subtext">This data is based on last 6 month observation</div>
      <div className="sdoh-parameter-list">
        <div
          className={`sdoh-parameter living ${
            data?.sdohParameters?.includes("living") ? "active" : ""
          }`}
        >
          <div className="parameter-image"></div>
          <div className="parameter-value">Living</div>
        </div>
        <div
          className={`sdoh-parameter food ${
            data?.sdohParameters?.includes("food") ? "active" : ""
          }`}
        >
          <div className="parameter-image"></div>
          <div className="parameter-value">Food</div>
        </div>
        <div
          className={`sdoh-parameter transport ${
            data?.sdohParameters?.includes("transport") ? "active" : ""
          }`}
        >
          <div className="parameter-image"></div>
          <div className="parameter-value">Transport</div>
        </div>
        <div
          className={`sdoh-parameter utility ${
            data?.sdohParameters?.includes("utility") ? "active" : ""
          }`}
        >
          <div className="parameter-image"></div>
          <div className="parameter-value">Utility</div>
        </div>
        <div
          className={`sdoh-parameter safety ${
            data?.sdohParameters?.includes("safety") ? "active" : ""
          }`}
        >
          <div className="parameter-image"></div>
          <div className="parameter-value">Safety</div>
        </div>
      </div>
    </div>
  );
}

export default SDOH;
