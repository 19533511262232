import React, { useEffect, useState } from "react";
import { Avatar } from "@/components";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "./MemberDetails.scss";

import { groupDynamics } from "@/services/dummyData";

const getRandomNumber = (min, max) => {
  return Math.floor(Math.random() * (max - min) + min);
};

function MemberDetails(props) {
  // const { data } = props;
  const data = groupDynamics.members;
  const [selectedMember, setSelectedMember] = useState(null);
  useEffect(() => {
    if (data.length > 0) {
      setSelectedMember(data[0]);
      // setInterval(() => {
      //   setSelectedMember(data[getRandomNumber(0, data.length)]);
      // }, 5000);
    }
  }, [data]);
  return (
    <div className="member-details-widget">
      <div className="member-list-container">
        <div className="member-list-label">
          Talk Time <span className="balanced-label">Balanced</span>
        </div>
        <div className="member-list">
          {data.map(member => {
            let talktimeValue = member.talktime;
            return (
              <div
                className="member-avatar-container"
                key={member.userId}
                onClick={() => setSelectedMember(member)}
              >
                <div className="member-avatar">
                  {/* <svg style={{ height: 0, position: "absolute" }}>
                    <defs>
                      <linearGradient id={`member-avatar-circle-${member.userId}`}>
                        <stop stopColor={member.talktime < 40 ? "#FFC502" : "#08D7BE"} />
                        <stop
                          offset="1"
                          stopColor={member.talktime < 40 ? "#FF6E05" : "#03ABE0"}
                        />
                      </linearGradient>
                    </defs>
                  </svg>
                  <CircularProgressbarWithChildren
                    strokeWidth={10}
                    value={member.talktime}
                    className="member-talktime-circle"
                    styles={{
                      path: {
                        stroke: `url(#member-avatar-circle-${member.userId})`,
                      },
                      trail: {
                        stroke: "#fff",
                      },
                    }}
                  >
                    <Avatar
                      name={member.name}
                      imageSrc={member.memberPhoto}
                    />
                  </CircularProgressbarWithChildren> */}
                  <Avatar
                    name={member.name}
                    imageSrc={member.memberPhoto}
                  />
                </div>
                <div className="member-talktime-container">
                  <div className="member-talktime">
                    <div className="member-name">{member.name}</div>
                    <div className="member-talktime">{member.talktime} min</div>
                  </div>
                  <div className="talktime-bar-container">
                    <div
                      className={`talktime-bar ${talktimeValue >= 10 ? "balanced" : ""}`}
                      style={{ width: `${(talktimeValue * 100) / 45}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default MemberDetails;
