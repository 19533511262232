import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import EmotionalTemp from "@/assets/emotional-temperature.svg";
import EmotionalTempLow from "@/assets/emotional-temperature-low.svg";
import EmotionalTempMedium from "@/assets/emotional-temperature-medium.svg";
import EmotionalTempHigh from "@/assets/emotional-temperature-high.svg";
import EnergyLevel from "@/assets/energy-level.svg";
import EnergyLevelLow from "@/assets/energy-level-low.svg";
import EnergyLevelMedium from "@/assets/energy-level-medium.svg";
import EnergyLevelHigh from "@/assets/energy-level-high.svg";
import ConnectionLevel from "@/assets/connection-level.svg";
import ConnectionLevelLow from "@/assets/connection-level-low.svg";
import ConnectionLevelMedium from "@/assets/connection-level-medium.svg";
import ConnectionLevelHigh from "@/assets/connection-level-high.svg";
import "./SessionLevels.scss";

import { groupDynamics } from "@/services/dummyData";

function SessionLevels(props) {
  // const { data } = props;
  const data = groupDynamics.meetingLevels;
  return (
    <div className="session-levels-widget">
      <div className="session-level-tooltip emotional-temp">
        <div className="tooltip-title">Group Emotional Temperature</div>
        <div className="emotional-level-list">
          <EmotionalLevel
            label={"Anxious"}
            value={data.emotionalTemperature.anxious}
          />
          <EmotionalLevel
            label={"Relaxed"}
            value={data.emotionalTemperature.relaxed}
          />
        </div>
      </div>
      <div className="session-level-tooltip">
        <div className="tooltip-title">Group Health</div>
        <LevelCircle
          levelType="energy"
          levelValue={data.health}
        />
      </div>
      <div className="session-level-tooltip">
        <div className="tooltip-title">Group Energy Level</div>
        <LevelCircle
          levelType="energy"
          levelValue={data.energyLevel}
        />
      </div>
      <div className="session-level-tooltip">
        <div className="tooltip-title">Group Connection</div>
        <LevelCircle
          levelType="energy"
          levelValue={data.connection}
        />
      </div>
    </div>
  );
}

export default SessionLevels;

const LevelCircle = props => {
  return (
    <div className="level-circle-chart">
      <svg style={{ height: 0 }}>
        <defs>
          <linearGradient
            id={props.levelType === "energy" ? "energy-level-circle" : "connection-level-circle"}
          >
            <stop stopColor={props.levelValue < 40 ? "#FFC502" : "#08D7BE"} />
            <stop
              offset="1"
              stopColor={props.levelValue < 40 ? "#FF6E05" : "#03ABE0"}
            />
          </linearGradient>
        </defs>
      </svg>
      <CircularProgressbarWithChildren
        strokeWidth={10}
        value={props.levelValue}
        className="level-circle"
        styles={{
          path: {
            stroke: `url(#${
              props.levelType === "energy" ? "energy-level-circle" : "connection-level-circle"
            })`,
          },
          trail: {
            stroke: "#F0F3F7",
          },
        }}
      >
        <div className="level-value">{props.levelValue}%</div>
      </CircularProgressbarWithChildren>
    </div>
  );
};

const EmotionalLevel = props => {
  return (
    <div className="emotional-level-container">
      <div className="level-label">{props.label}</div>
      <div className="level-bar-container">
        <div
          className={`level-bar-value ${props.value < 5 ? "low" : "high"}`}
          style={{ width: `${(props.value * 100) / 10}%` }}
        ></div>
        <div className="level-bar"></div>
      </div>
      <div className="level-extreme-values">
        <div className="extreme-value">0</div>
        <div className="extreme-value">10</div>
      </div>
    </div>
  );
};
