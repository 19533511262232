import React, { useEffect, useRef, useState } from "react";
import { RichTextEditor } from "@/components/RichTextEditor";
import { useParams } from "react-router-dom";
import { marked } from "marked";
import { dischargeSummary, weeklySummary, dischargeSummaryJson } from "@/services/dummyData";
import { Modal, Accordion } from "react-bootstrap";
import "./DischargeSummary.scss";
import { PrimaryButton, SecondaryButton, Loader } from "@/components";
import html2pdf from "html2pdf.js";
import { toast } from "react-toastify";
import { Overlay, Tooltip } from "react-bootstrap";
import Calendar from "react-calendar";
import moment from "moment-timezone";

const convertMarkdownToHtml = text => {
  return marked.parse(text);
};

const weekData = [
  { week: 1, date: "2024-08-05" },
  { week: 2, date: "2024-08-12" },
  { week: 3, date: "2024-08-19" },
  { week: 4, date: "2024-08-26" },
];

const DATE_FORMAT = "MMM DD, YYYY";

function DischargeSummary(props) {
  const [data, setData] = useState("");
  const { patientId } = useParams();
  const [supportingEvidenceList, setSupportingEvidenceList] = useState([]);
  const [currentView, setCurrentView] = useState("date");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [showStartCalendar, setShowStartCalendar] = useState(false);
  const [showEndCalendar, setShowEndCalendar] = useState(false);
  const startCalendarRef = useRef(null);
  const endCalendarRef = useRef(null);
  // useEffect(() => {
  //   if (props.dischargeSummary.data) {
  //     if (props.dischargeSummary.data.dischargeSummary) {
  //       setData(
  //         convertMarkdownToHtml(props.dischargeSummary.data.dischargeSummary) ||
  //           props.dischargeSummary.data.dischargeSummary
  //       );
  //     }
  //     if (props.dischargeSummary.data.supportingEvidenceList?.length > 0) {
  //       setSupportingEvidenceList(props.dischargeSummary.data.supportingEvidenceList);
  //     }
  //   }
  // }, [props.dischargeSummary.data]);
  useEffect(() => {
    if (dischargeSummaryJson) {
      if (dischargeSummaryJson.dischargeSummary) {
        setData(
          convertMarkdownToHtml(dischargeSummaryJson.dischargeSummary) ||
            dischargeSummaryJson.dischargeSummary
        );
      }
      if (dischargeSummaryJson.supportingEvidenceList?.length > 0) {
        setSupportingEvidenceList(dischargeSummaryJson.supportingEvidenceList);
      }
    }
  }, []);
  const downloadPdf = () => {
    // Create a temporary container for the HTML string
    const container = document.createElement("div");
    container.innerHTML = data;

    // Append the container to the body (or any other element)
    document.body.appendChild(container);
    var opt = {
      margin: 1,
      filename: "discharge-summary.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    };
    // Convert the HTML to PDF and download
    // html2pdf().from(container).set(opt).save();
    html2pdf()
      .from(container)
      .set(opt)
      .toPdf()
      .get("pdf")
      .then(pdf => {
        // Remove the container from the body after PDF is generated
        document.body.removeChild(container);

        // Save the PDF
        pdf.save("discharge-summary.pdf");
      });
  };
  const onSaveChanges = () => {
    setTimeout(() => {
      toast.success("Changes Saved");
    }, 500);
  };
  const generateDichargeSummary = async () => {
    setCurrentView("summary");
    return;
    if (startDate && endDate) {
      let sessions =
        props.sessions?.filter(session => {
          return (
            session.sessionCategory === "INDIVIDUAL" &&
            session.patientId === patientId &&
            !!session.startTime &&
            new Date(session.startTime) >= new Date(startDate) &&
            new Date(session.startTime) <= new Date(endDate)
          );
        }) || [];
      sessions = sessions.sort(
        (session1, session2) => new Date(session2.startTime) - new Date(session1.startTime)
      );
      if (sessions?.length > 0) {
        let dischargeData = await props.generateDischargeSummary({ sessions });
        if (dischargeData) {
          if (dischargeData.dischargeSummary) {
            setData(
              convertMarkdownToHtml(dischargeData.dischargeSummary) ||
                dischargeData.dischargeSummary
            );
          }
          if (dischargeData.supportingEvidenceList?.length > 0) {
            setSupportingEvidenceList(dischargeData.supportingEvidenceList);
          }
          setCurrentView("summary");
        } else {
          if (patientId === "25d9ec2b-8ea5-43c8-b582-e6be2bb333f9") {
            if (dischargeSummaryJson.dischargeSummary) {
              setData(
                convertMarkdownToHtml(dischargeSummaryJson.dischargeSummary) ||
                  dischargeSummaryJson.dischargeSummary
              );
            }
            if (dischargeSummaryJson.supportingEvidenceList?.length > 0) {
              setSupportingEvidenceList(dischargeSummaryJson.supportingEvidenceList);
            }
            setCurrentView("summary");
          } else {
            toast.error("Error generating discharge summary");
          }
        }
      } else {
        toast.error("No sessions found in this timerange");
      }
    }
  };
  return (
    <Modal
      show={props.modalOpen}
      onHide={() => props.toggleModal(false)}
      className="discharge-summary-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title>Discharge Summary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Loader loading={props.dischargeSummary.loading}>
          {currentView === "date" ? (
            <div className="discharge-summary-date-selection">
              <div className="discharge-summary-date-selection-label">
                Generate Discharge Summary from
              </div>
              <div className="date-list">
                <div className="discharge-date-wrapper">
                  <div className="discharge-date-label">Admission Date:</div>
                  <div
                    className="discharge-date-container"
                    ref={startCalendarRef}
                    onClick={() => {
                      setShowStartCalendar(!showStartCalendar);
                      if (showEndCalendar) {
                        setShowEndCalendar(false);
                      }
                    }}
                  >
                    <div className="discharge-date">
                      {startDate ? moment(startDate).format(DATE_FORMAT) : "MM DD, YYYY"}
                    </div>
                  </div>
                  <Overlay
                    target={startCalendarRef.current}
                    show={showStartCalendar}
                    placement="bottom"
                    rootClose={true}
                  >
                    {props => (
                      <Tooltip
                        id="start-calendar-overlay"
                        className="calendar-overlay"
                        {...props}
                      >
                        <Calendar
                          onChange={date => {
                            setStartDate(date);
                            setShowStartCalendar(false);
                          }}
                          value={startDate ? moment(startDate) : null}
                        />
                      </Tooltip>
                    )}
                  </Overlay>
                </div>
                <div className="separator">To</div>
                <div className="discharge-date-wrapper">
                  <div className="discharge-date-label">Discharge Date:</div>
                  <div
                    className="discharge-date-container"
                    ref={endCalendarRef}
                    onClick={() => {
                      setShowEndCalendar(!showEndCalendar);
                      if (showStartCalendar) {
                        setShowStartCalendar(false);
                      }
                    }}
                  >
                    <div className="discharge-date">
                      {endDate ? moment(endDate).format(DATE_FORMAT) : "MM DD, YYYY"}
                    </div>
                  </div>
                  <Overlay
                    target={endCalendarRef.current}
                    show={showEndCalendar}
                    placement="bottom"
                    rootClose={true}
                  >
                    {props => (
                      <Tooltip
                        id="end-calendar-overlay"
                        className="calendar-overlay"
                        {...props}
                      >
                        <Calendar
                          onChange={date => {
                            setEndDate(date);
                            setShowEndCalendar(false);
                          }}
                          value={endDate ? moment(endDate) : null}
                        />
                      </Tooltip>
                    )}
                  </Overlay>
                </div>
              </div>
              <PrimaryButton
                disabled={!startDate || !endDate}
                onClick={generateDichargeSummary}
              >
                Generate
              </PrimaryButton>
            </div>
          ) : (
            <>
              <div className="action-btns">
                <SecondaryButton onClick={onSaveChanges}>Save Changes</SecondaryButton>
                <PrimaryButton onClick={downloadPdf}>Download PDF</PrimaryButton>
              </div>
              <div className="discharge-summary-container">
                <div className="discharge-summary-content">
                  <RichTextEditor
                    value={data}
                    setValue={setData}
                    hideCopy={true}
                  />
                </div>
                <div className="weekly-summary-container">
                  <Accordion
                    defaultActiveKey={"week-1"}
                    className="weekly-summary-list"
                  >
                    {supportingEvidenceList?.map((week, index) => {
                      return (
                        <Accordion.Item
                          eventKey={`week-${week.weekNumber}`}
                          key={`week-${week.weekNumber}`}
                          className="weekly-summary-item"
                        >
                          <Accordion.Header className="weekly-summary-item-name">
                            Week {week.weekNumber} (Date: {week.date})
                          </Accordion.Header>
                          <Accordion.Body className="weekly-summary-item-content">
                            {Object.keys(week.filteredContentFromTranscript).map(section => {
                              return (
                                <div
                                  className="discharge-summary-section"
                                  key={section}
                                >
                                  <div className="section-title">{section}</div>
                                  {week.filteredContentFromTranscript[section].length > 0 && (
                                    <div className="section-transcripts">
                                      {week.filteredContentFromTranscript[section].map(
                                        (transctipt, index) => {
                                          let memberName = Object.keys(transctipt)?.[0] || "";
                                          let transcriptText =
                                            transctipt?.[Object.keys(transctipt)?.[0]] || "";
                                          if (memberName === "text") {
                                            memberName = "";
                                          }
                                          return (
                                            <div
                                              className="transcript-item"
                                              key={index}
                                            >
                                              <div className="transcript-text">
                                                <span className="transcript-name">
                                                  {memberName}
                                                  {memberName ? ":" : ""}
                                                </span>{" "}
                                                {transcriptText}
                                              </div>
                                            </div>
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })}
                  </Accordion>
                </div>
              </div>
            </>
          )}
        </Loader>
      </Modal.Body>
    </Modal>
  );
}

export default DischargeSummary;
