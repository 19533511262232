import React, { useState, useEffect } from "react";
import { Loader, PrimaryButton, RichTextEditor } from "@/components";
import { toast } from "react-toastify";
import "./Narrative.scss";

import { narrativeData as nData } from "@/services/dummyData";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

function Narrative(props) {
  const [narrativeData, setNarativeData] = useState(nData);
  return (
    <Loader loading={props.loading || props.updateLoading}>
      <div className="narrative-container">
        <div className="demo-deta-disclaimer">
          *Demo data{APP_ENV === "PROD" ? ". Feature coming soon" : ""}
        </div>
        <div className="narrative-rich-container">
          <RichTextEditor
            value={narrativeData}
            setValue={setNarativeData}
          />
          <div className="water-mark">Demo Data</div>
        </div>
      </div>
    </Loader>
  );
}

export default Narrative;
