import React, { useState, useEffect } from "react";
import { Loader, PrimaryButton, RichTextEditor } from "@/components";
import { toast } from "react-toastify";
import "./BillingCodes.scss";

import { billingCodesData, groupBillingCodesData } from "@/services/dummyData";

const APP_ENV = import.meta.env.VITE_APP_ENV || "PROD";

function BillingCodes(props) {
  const [billingData, setBillingData] = useState(
    props.sessionCategory === "group" ? groupBillingCodesData : billingCodesData
  );
  return (
    <Loader loading={props.loading || props.updateLoading}>
      <div className="billing-codes-container">
        <div className="demo-deta-disclaimer">
          *Demo data{APP_ENV === "PROD" ? ". Feature coming soon" : ""}
        </div>
        <div className="billing-rich-container">
          <RichTextEditor
            value={billingData}
            setValue={setBillingData}
          />
          <div className="water-mark">Demo Data</div>
        </div>
      </div>
    </Loader>
  );
}

export default BillingCodes;
